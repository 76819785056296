import React, { useState, useEffect, useContext } from "react";

import classNames from "classnames";

import DataTable from "../../../Templates/DataTable";
import IncidentReportRow from "./IncidentReportRow";
import Loader from "../../../Templates/Loader";

import api from "../../../../services/api";
import AppContext from "../../../../context/AppContext";
import { navigate } from "gatsby";
import Pagination from "../../../Templates/Pagination";

const IncidentReportTable = () => {
  const [incidentReports, setIncidentReports] = useState([]);
  const [searchParams, setSearchParams] = useState({ page: 1 });
  const [activeTab, setActiveTab] = useState("incidentReports");
  const [isLoading, setLoading] = useState(false);
  const [pages, setPages] = useState(0);
  const appContext = useContext(AppContext);

  const fetchData = () => {
    setLoading(true);
    const objectToParams = new URLSearchParams(searchParams);
    api
      .get(`/incident_reports/?${objectToParams.toString()}`)
      .then(response => {
        setValues(response.data.results, Math.ceil(response.data.count / 30));
      })
      .catch(error => console.error());
  };

  useEffect(() => {
    fetchData();
  }, []);

  const navigatePages = pageNumber => {
    setSearchParams(prevParams => {
      return { ...prevParams, page: pageNumber };
    });
  };

  useEffect(() => fetchData(), [searchParams]);

  const setValues = (incidentReport, pages) => {
    setIncidentReports(incidentReport);
    setPages(pages);
    setLoading(false);
  };

  const titles = [
    "No.",
    "Status",
    "Site",
    "Source",
    "Date Sent to MedGrocer",
    "Complainant",
    "Category",
  ];

  let reports;

  // const sortedReports = reports.sort((a, b) => b.dateSentToMg - a.dateSentToMg);

  if (activeTab === "incidentReports") {
    reports = incidentReports.filter(report => report.reportType === "IR");
  } else if (activeTab === "escalationReports") {
    reports = incidentReports.filter(report => report.reportType === "ER");
  }

  return (
    <div>
      <div className="tabs is-boxed">
        <ul>
          <li
            className={classNames({
              "is-active": activeTab === "incidentReports",
            })}
            onClick={() => setActiveTab("incidentReports")}
          >
            <span>Incident Reports</span>
          </li>
          <li
            className={classNames({
              "is-active": activeTab === "escalationReports",
            })}
            onClick={() => setActiveTab("escalationReports")}
          >
            <span>Escalation Reports</span>
          </li>
        </ul>
      </div>
      {isLoading && <Loader />}
      {reports.length > 0 ? (
        <DataTable headerTitles={titles} isSelectable>
          {reports.map((incidentReport, index) => (
            <IncidentReportRow
              incidentReport={incidentReport}
              index={index}
              callbackFunction={() => {
                appContext.setActiveReport(incidentReport);
                navigate("/admin/incident-reports/view");
              }}
            />
          ))}
        </DataTable>
      ) : (
        <p className="has-text-centered p-2">No data available.</p>
      )}
      {pages > 1 && (
        <Pagination pages={pages} callbackFunction={navigatePages} />
      )}
    </div>
  );
};

export default IncidentReportTable;
