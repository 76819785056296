import React from "react";
import moment from "moment";

const IncidentReportRow = ({ incidentReport, callbackFunction, index }) => {
  let {
    status,
    dateSentToMg,
    clinicName,
    complainant,
    primaryCategory,
    source,
  } = incidentReport;

  return (
    <tr className="is-selectable" onClick={callbackFunction}>
      <td>{index + 1}</td>
      <td>{status}</td>
      <td>{clinicName}</td>
      <td>{source}</td>
      <td>{moment.utc(dateSentToMg).format("MMM DD YYYY")}</td>
      <td>{complainant}</td>
      <td>{primaryCategory}</td>
    </tr>
  );
};

export default IncidentReportRow;
