import React, { useContext, useEffect } from "react";

import Layout from "../../layout/Layout";
import NewIncidentReport from "../../components/Admin/IncidentReports/NewIncidentReportButton";
import IncidentReportTable from "../../components/Admin/IncidentReports/IncidentReportLogs/IncidentReportTable";
import AppContext from "../../context/AppContext";
import SEO from "../../layout/Seo";

const IncidentReportLogs = () => {
  const appContext = useContext(AppContext);

  useEffect(() => {
    appContext.setActiveReport({});
  }, []);

  return (
    <Layout pageTitle="Incident Reports" hasRightButton={<NewIncidentReport />}>
      <SEO title="Incident Reports" />
      <IncidentReportTable />
    </Layout>
  );
};

export default IncidentReportLogs;
